import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer = {
	cinema: {
		address: 'пр. Ленина, 59/1',
		place: null,
		phoneNumbers: ['8 (937) 479-06-00', '8 (34767) 4-00-71'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/verbacinemaokt',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://apps.apple.com/ru/app/кинокасса-билеты-в-кино/id1493004732',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.kinokassa&hl=ru',
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: 'https://yandex.ru/maps/-/CGhWuTm4',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: true,
	},
	feedback: true,
};
